<template>
	<CommonPage id="privacy" title="Privacy Policy">
		<section class="privacy-content">

			<div>
				<p>
					We are committed to respecting and protecting each individual’s privacy
					rights. This Privacy Policy explains how Click Tech Limited collect, use,
					disclose or share end user (“you” or “your”) information through our website
					at
					<a :href="`https://${host}`" previewlistener="true">
						{{host}}
					</a>
					(“Website”) , and any of our products and/or services (collectively, the
					“Services”), and whether you access the Services directly or through a
					third party.
				</p>
				<p>
					Please understand that, except as specifically described below, this Privacy
					Policy does not apply to information collected by third parties, such as
					other companies, or third-party websites or mobile applications, even if
					any are linked to or accessible through the Services.
				</p>
				<p>
					We collect information from users when users (i) visit our Website; (ii)
					view ads served by our Advertising Service; (iii) interact with our Advertising
					Service; or (iv) interact with the platforms, applications, websites or
					any other products and/or services provided by our business partners (e.g.
					app publisher, advertiser).
				</p>
				<p>
					When you use the Services, where applicable under relevant laws, you consent
					to our collection, use, and disclosure of information about you as described
					in this Privacy Policy. Under other applicable relevant laws, we may collect,
					use, disclosure, or otherwise your information based on the appropriate
					legal basis. Please review this Privacy Policy before using our Services
					or visiting our Website. We may amend this Privacy Policy from time to
					time. The amended Privacy Policy will be displayed on our Website. Please
					check our Privacy Policy regularly to ensure you have read the latest version.
					Where applicable under relevant laws, continued use of the Services after
					the effective date will be your acknowledgement that have accepted the
					revised Privacy Policy.
				</p>
				<p>
					If you have any questions or concerns about our information practices,
					please contact us at
					<a href="mailto:newsbig@outlook.com">
						newsbig@outlook.com
					</a>
					.
				</p>
				<h3>
					1. What Information Is Collected and How Do We Collect Your Information?
				</h3>
				<p>
					We may collect, transmit, store, and otherwise process information about
					you in connection with your use of the Services, including any information
					you send to or through the Services. We use that information to provide
					the Services’ functionality, fulfill your requests, improve the Services’
					quality, engage in research and analysis relating to the Services, personalize
					your experience, track usage of the Services, provide feedback to third
					party businesses that are listed on the Services, display relevant advertising,
					market the Services, provide customer support, message you, back up our
					systems, allow for disaster recovery, enhance the security of the Services,
					and comply with legal obligations. Even when we do not retain such information,
					it still must be transmitted to our servers initially and stored long enough
					to process.
				</p>
				<p>
					We may collect the following information from or about our users when
					they access our Services:
				</p>
				<ul>
					<li>
						Information which users provide to us, including names, gender, birth
						dates, personal ID card numbers, addresses, telephone numbers, email addresses,
						occupations, education, work experience and related information.
					</li>
					<li>
						Passive information collected when users use the Services, such as a user’s
						browser information (user agent), browser type, IP host address, mobile
						device type, the Identifier for Advertising (IDFA), Google Advertising
						ID (GAID), whether user viewed, clicked or otherwise interacted with the
						advertisement, and other device identifiers, domain name, pages visited,
						and the length of your user sessions using various technologies and means,
						such as IP address, cookies, internet tags and navigational data collection.
					</li>
					<li>
						We also use cookies to collect certain information about our users. Cookies
						collect information on the device and network used to access the domain,
						and the date and time that users access the domain. This information is
						for internal use only, such as optimizing performance of the Services.
						The information cannot identify the individual user.
					</li>
					<li>
						Information from other third party partners of us, such as companies that
						provide services to us and our business partners.
					</li>
				</ul>
				<h3>
					2. How Do We Use Your Information?
				</h3>
				<p>
					We use the information, including any personal information, provided by
					users to provide our Services and for internal business purposes, such
					as improving our services and assist our customers and partners in improving
					their products and services; compiling aggregate data for internal and
					external business purposes; preventing potentially illegal activities;
					in support of user accounts or customer service requests; in relation with
					any required governmental or legal action ; and other uses in accordance
					with our respective Terms and Conditions.
				</p>
				<p>
					We may also use the information that we collect about your device to display
					advertisements on your device, which may include interest-based advertising
					customized to your interests, preferences, locations, for bidding to serve
					advertisements and to determine which ads are most effective, to analyze
					and provide our demand partners or advertisers reports on the effectiveness
					of advertisements and campaigns, including across different types of devices
					based on our determination of devices that are related to the same person,
					and to build custom audience segments to enable our customers and partners
					better target their advertisements and offers.
				</p>
				<h4>
					The legal basis for using your personal information
				</h4>
				<p>
					We will only collect, use, share and otherwise process your personal information
					where we are satisfied that we have an appropriate legal basis to do this.
					Our processing activities and our legal basis is set out below:
				</p>
				<div class="info-table">
					<table border="1" cellspacing="0" cellpadding="00" frame="void" rules="all">
						<tbody>
							<tr>
								<td style="padding: 20px;">
									<p>
										Categories of personal information
									</p>
								</td>
								<td style="padding: 20px;">
									<p>
										Processing activities
									</p>
								</td>
								<td style="padding: 20px;">
									<p>
										The legal basis we rely upon
									</p>
								</td>
							</tr>
							<tr>
								<td style="padding: 20px;">
									<p>
										Contact information:
									<br>
									For example:
									<br>
									Names ;Addresses;
									<br>
									Telephone numbers;
									<br>
									Email addresses; etc.
									<br>
									Other information about you which you provide to us such as your gender,
									date of birth, and education.
									</p>
								</td>
								<td rowspan="2" style="padding: 20px;">
									<p>
										Providing services and optimizing services:
									<br>
									(1)To meet the requirement of ad offer.
									<br>
									(2)Analyse device data to determine performance of advertisement campaigns
									and to verify the validity of the traffic.
									<br>
									(3)Further optimize the effectiveness of ads and to exclude malicious
									fraudulent traffic.
									<br>
									(4)Analyse ongoing business.
									<br>
									(5)Optimize in-house App.
									<br>
								</p>
								</td>
								<td rowspan="2" style="padding: 20px;">
									<p>
										We rely on our legitimate interests:
									<br>
									a) to track and reduce the amount of fraudulent traffic and to verify
									the success of advertising campaigns; and
									<br>
									b) To prevent and detect crime, and in each case we have assessed and
									determined that our legitimate interests are not overridden by the rights
									and freedoms of the data subject to whom such personal data relates
								</p>
								</td>
							</tr>
							<tr>
								<td style="padding: 20px;">
									<p>
										Device information:

									<br>
									IDFA/GAID/AID; Device type; User agent; Operating system;
									<br>
									Operating system version;
									<br>
									IP address; MSISDN;
									<br>
									Device ID;IMSI; and browser information.
								</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					We may also use your personal information where necessary to comply with
					a relevant legal or regulatory obligation that we have.
				</p>
				<h3>
					3. How Do We Share Your Information?
				</h3>
				<p>
					We may share your personal information with our business partners, including
					publishers, supply partners, demand partners, advertisers, marketing partners,
					data tracking companies, service providers (which may include IT and related
					services; fraud detection, information and services; payment processors;
					customer service providers; and vendors to support the provision of the
					Services) and with our subsidiaries and affiliates so that we can better
					provide our Services.
				</p>
				<p>
					In particular, we will share your personal information with:
				</p>
				<ul>
					<li>
						our advertisers, to identify and verify the validity of advertising promotion
						placed, and to verify the success of advertising campaigns;
					</li>
					<li>
						third party attributions, such as Appsflyer or Adjust, for monitoring
						and fraud detection purposes; and
					</li>
					<li>
						government and/or regulatory authorities, to comply with our legal and
						regulatory obligations.
					</li>
				</ul>
				<p>
					Except as otherwise described in our Privacy Policy, we will not disclose
					personal information to any third party unless we believe that disclosure
					is necessary: (1) to comply with applicable law or to respond to a subpoena,
					court order, search warrant, or other legal process received by us , whether
					or not a response is required by applicable law; (2) to protect or enforce
					our rights or the rights of a third party; (3) to protect the safety of
					members of the public and our users; or (4) to respond to an emergency.
					We reserve the right to transfer personal information to a successor in
					interest that acquires rights to that information as a result of a sale
					of us or substantially all of our assets to that successor in interest
					or of a merger between us and such successor in interest or as a result
					of division. We also reserve the right to disclose personal information
					as necessary to enable third-party service providers to support and maintain
					the operation of our Services, or to disclose personal information to other
					third parties performing services in connection with the operation of our
					business.
				</p>
				<h4>
					Transferring Personal Information Globally
				</h4>
				<p>
					We operate on a global basis. Accordingly, your personal information may
					be transferred and stored in countries outside the Peoples Republic of
					China(“PRC”), the European Economic Area (“EEA”) and the United Kingdom
					(“UK”), such as US, Brazil, Singapore, that are subject to different standards
					of data protection. We will take appropriate steps to ensure that transfers
					of the personal information are in accordance with applicable law and carefully
					managed to protect your privacy rights and interest and transfers are limited
					to countries which are recognised as providing an adequate level of legal
					protection or where we can be satisfied that alternative arrangement are
					in place to protect your privacy rights. To ensure the protection of your
					personal information consistent with applicable law, such transfer takes
					place outside the PRC, the EEA and the UK as follows:
				</p>
				<ul>
					<li>
						(1) If it is necessary to transfer such data from within the PRC to outside
						PRC, such transfer will be made pursuant to relevant laws and regulations.
					</li>
					<li>
						(2) Outside of the EEA and the United Kingdom, such transfer will be made
						pursuant to the EU Model Clauses, the EU-US Privacy Shield certification,
						or other acceptable legal mechanisms of which you can request a copy via
						<a href="mailto:newsbig@outlook.com">
							newsbig@outlook.com
						</a>
						.
					</li>
				</ul>
				<h3>
					4. How Do We Protect the Information?
				</h3>
				<p>
					We use various safeguards to protect the personal information submitted
					to us, both during transmission and after we receive it. For example, we
					have implemented and maintain appropriate technical and organizational
					security measures to protect the information we collect. We use multiple
					electronic, procedural, and physical security measures to protect against
					unauthorized or unlawful use or alteration of information, and against
					any accidental loss, destruction, or damage to information.
				</p>
				<p>
					We also have a Data Protection Officer (“DPO”) according to GDPR. The
					DPO is responsible for monitoring our compliance with GDPR and cooperating
					with supervisory authority. His contact is
					<a href="mailto:newsbig@outlook.com">
						newsbig@outlook.com
					</a>
					.
				</p>
				<p>
					However, no method of transmission over the internet or via mobile device,
					or method of electronic storage, is 100% secure. Therefore, while we strive
					to use commercially acceptable means to protect your personal information,
					we cannot guarantee its absolute security.
				</p>
				<h3>
					5. How Long Do We Keep the Information For?
				</h3>
				<p>
					We may retain the information relating to a user for as long as a user
					uses the Services and for the purposes as stated in this Privacy Policy,
					unless otherwise required by law or applicable regulations. In specific
					circumstances, we may store your personal information for longer periods
					of time so that we have an accurate record of your dealings with us in
					the event of any complaints or challenges, or if we reasonably believe
					there is a prospect of litigation relating to your personal information
					or dealing.
				</p>
				<p>
					Notwithstanding the foregoing, we may retain and use such information
					in a non-identifiable, aggregated format, as necessary for internal analytical
					purposes, to comply with its legal obligations, to resolve disputes and
					enforce agreements. We will make efforts to guarantee that personal information
					is kept for no longer than is necessary for the purposes for which the
					personal information is processed.
				</p>
				<h3>
					6. User Rights to Their Information
				</h3>
				<p>
					Users have certain rights available to them when it comes to their information.
				</p>
				<p>
					In accordance with the Personal Information Protection Law of the People's
					Republic of China, we shall process your personal information only under
					the premise that there is a legal basis. You have the following rights
					to your personal information:
				</p>
				<h4>
					Right to access and copy personal information
				</h4>
				<p>
					You have the right to access your personal information and to receive
					copies of your personal information, subject to the exceptions provided
					by laws and regulations.
				</p>
				<h4>
					Right to correct or delete personal information
				</h4>
				<p>
					If you find that we have processed errors in the personal information,
					you have the right to request us to make correction. We may verify the
					accuracy of the personal information prior to correcting it.
					<br>
					You may request us to delete your personal information in the following
					situations:
				</p>
				<ul>
					<li>
						The handling purpose has been achieved, is impossible to achieve, or the
						personal information is no longer necessary to achieve the handling purpose;
					</li>
					<li>
						Personal information handlers cease the provision of products or services,
						or the retention period has expired;
					</li>
					<li>
						You withdraw consent;
					</li>
					<li>
						We process personal information in violation of laws, administrative regulations
						or agreements;
					</li>
					<li>
						Other circumstances provided for by laws or administrative regulations.
					</li>
					<li>
						If the retention period provided by laws or administrative regulations
						has not expired or the deletion of the personal information is technically
						difficult to accomplish, we will stop processing the information other
						than storage and adoption of necessary security protection measures.
					</li>
				</ul>
				<h4>
					Right to transfer your personal information
				</h4>
				<p>
					You may make a request to us to transfer your personal information to
					a designated personal information processor.
				</p>
				<h4>
					Right to withdraw your consent
				</h4>
				<p>
					You have the right to withdraw your consent for the processing of personal
					information based on your consent.
				</p>
				<h4>
					Right to request us for explaination
				</h4>
				<p>
					You have the right to request us to explain the rules governing the processing
					of personal information.
					<br>
					If you have any questions about our compliance with this Privacy Policy
					and relevant data protection laws, or if you wish to exercise your rights,
					you can contact us at
					<a href="mailto:newsbig@outlook.com">
						newsbig@outlook.com
					</a>
					.
				</p>
				<h4>
					European Union and United Kingdom Residents
				</h4>
				<p>
					For residents of the EU and UK, we collect and process information about
					the users who live in the EEA and the UK only on where we have legal bases
					under applicable EU and UK laws. Subject to certain exemptions, and in
					some cases dependent upon the processing activity we are undertaking, you
					have certain rights in relation to your personal information, including:
				</p>
				<h4>
					Right to access personal information
				</h4>
				<p>
					You have a right to request that we provide you with a copy of your personal
					information that we hold and you have the right to be informed of; (a)
					the source of your personal information; (b) the purposes, legal basis
					and methods of processing; (c) the data controller’s identity; and (d)
					the entities or categories of entities to whom your personal information
					may be transferred.
				</p>
				<h4>
					Right to rectify or erase personal information
				</h4>
				<p>
					You have a right to request that we rectify inaccurate personal information.
					We may seek to verify the accuracy of the personal information before rectifying
					it.
				</p>
				<p>
					You can also request that we erase your personal information in limited
					circumstances where:
				</p>
				<ul>
					<li>
						it is no longer needed for the purposes for which it was collected; or
					</li>
					<li>
						you have withdrawn your consent (where the data processing was based on
						consent); or
					</li>
					<li>
						following a successful right to object (see right to object below); or
					</li>
					<li>
						it has been processed unlawfully; or
					</li>
					<li>
						to comply with a legal obligation to which we are subject.
					</li>
				</ul>
				<p>
					We are not required to comply with your request to erase personal information
					if the processing of your personal information is necessary:
				</p>
				<ul>
					<li>
						for compliance with a legal obligation; or
					</li>
					<li>
						for the establishment, exercise or defence of legal claims.
					</li>
				</ul>
				<h4>
					Right to restrict the processing of your personal information
				</h4>
				<p>
					You can ask us to restrict your personal information, but only where:
				</p>
				<ul>
					<li>
						its accuracy is contested, to allow us to verify its accuracy; or
					</li>
					<li>
						the processing is unlawful, but you do not want it erased; or
					</li>
					<li>
						it is no longer needed for the purposes for which it was collected, but
						we still need it to establish, exercise or defend legal claims; or
					</li>
					<li>
						you have exercised the right to object, and verification of overriding
						grounds is pending.
					</li>
					<li>
						We can continue to use your personal information following a request for
						restriction, where:
					</li>
					<li>
						we have your consent; or
					</li>
					<li>
						to establish, exercise or defend legal claims; or
					</li>
					<li>
						to protect the rights of another natural or legal person.
					</li>
				</ul>
				<p>
					You can ask us to provide your personal information to you in a structured,
					commonly used, machine-readable format, or you can ask to have it transferred
					directly to another data controller, but in each case only where:
				</p>
				<ul>
					<li>
						the processing is based on your consent or on the performance of a contract
						with you; and
					</li>
					<li>
						the processing is carried out by automated means.
					</li>
				</ul>
				<h4>
					Right to object to the processing of your personal information
				</h4>
				<p>
					You can object to any processing of your personal information which has
					our legitimate interests as its legal basis, if you believe your fundamental
					rights and freedoms outweigh our legitimate interests.
					<br>
					If you raise an objection, we have an opportunity to demonstrate that
					we have compelling legitimate interests which override your rights and
					freedoms.
				</p>
				<h4>
					Right to object to how we use your personal information for direct marketing
					purposes
				</h4>
				<p>
					You can request that we change the manner in which we contact you for
					marketing purposes. You can request that we not transfer your personal
					information to unaffiliated third parties for the purposes of direct marketing
					or any other purposes.
				</p>
				<h4>
					Right to obtain a copy of personal information safeguards used for transfers
					outside your jurisdiction
				</h4>
				<p>
					You can ask to obtain a copy of, or reference to, the safeguards under
					which your personal information is transferred outside of the EU or UK.
					Where applicable under relevant laws, we may redact data transfer agreements
					to protect commercial terms.
				</p>
				<p>
					If you have any questions, concerns or complaints regarding our compliance
					with this Privacy Policy and the relevant data protection laws, or if you
					wish to exercise your rights, we encourage you to first contact our Data
					Protection Officer at
					<a href="mailto:newsbig@outlook.com">
						newsbig@outlook.com
					</a>
					.
				</p>
				<p>
					We may ask you for additional information to confirm your identity and
					for security purposes, before disclosing the personal information requested
					to you. We reserve the right to charge a fee where permitted by law, for
					instance if your request is manifestly unfounded or excessive. You can
					exercise your rights by contacting us. Subject to legal and other permissible
					considerations, we will make every reasonable effort to honour your request
					promptly or inform you if we require further information in order to fulfil
					your request. We may not always be able to fully address your request,
					for example if it would impact the duty of confidentiality we owe to others,
					or if we are legally entitled to deal with the request in a different way.
				</p>
				<p>
					We will investigate and attempt to resolve complaints and disputes and
					will make every reasonable effort to honour your wish to exercise your
					rights as quickly as possible, and in any event, within the timescales
					provided by data protection laws.
				</p>
				<p>
					You have a right to lodge a complaint with your local protection supervisory
					authority (i.e. your place of habitual residence, place or work or place
					of alleged infringement) at any time. We ask that you please attempt to
					resolve any issues with us before your local supervisory authority.
				</p>
				<h4>
					California Residents
				</h4>
				<p>
					Under the California Consumer Protection Act of 2018 (“CCPA“), California
					residents have certain rights around our collection, use, and sharing of
					their personal information.
				</p>
				<p>
					In accordance with CCPA, please see the following summary of personal
					information that we have collected in the past twelve (12) months, as well
					as the purposes of collection, and third parties with who we have shared
					such personal information in the past twelve (12) months.
				</p>
				<div class="info-table">
					<table border="1" cellspacing="0" cellpadding="00" frame="void" rules="all">
						<tbody>
							<tr>
								<td>
									<p>Categories of Personal Information</p>
								</td>
								<td>
									<p>Purposes of Collecting Personal Information</p>
								</td>
								<td>
									<p>Third Parties with Whom We Share Personal Information</p>
								</td>
							</tr>
							<tr>
								<td><p>
									Identifiers (such as names, gender, birth dates, personal ID card numbers,
									addresses, telephone numbers, email addresses, occupations, education,
									work experience and related information, Google Advertising ID (GAID))
								</p>
								</td>
								<td><p>
									Provide the Services
									<br>
									Internal research, including to maintain and develop the Services
									<br>
									Marketing, security and detection of unauthorized activities
									<br>
								</p>
								</td>
								<td>
									<p>
									Service providers
									<br>
									Subsidiaries &amp; Affiliates
									<br>
									Partners
								</p>
								</td>
							</tr>
							<tr>
								<td><p>
									Commercial information (such as purchases and transaction history)
								</p>
								</td>
								<td>
									<p>
									Provide the Services
									<br>
									Internal research, including to maintain and develop the Services
									<br>
									Marketing
									<br>
									Security and detection of unauthorized activities
								</p>
								</td>
								<td>
									<p>
										Service providers
										<br>
										Subsidiaries &amp; Affiliates
										<br>
										Partners
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										Internet and network activity information (or usage data, such as browsing
										or search history or interaction with websites)
									</p>
								</td>
								<td>
									<p>
										Provide the Services
										<br>
										Internal research, including to maintain and develop the Services
										<br>
										Marketing
										<br>
										Security and detection of unauthorized activities
									</p>
								</td>
								<td>
									<p>
										Service providers
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										Geolocation data
									</p>
								</td>
								<td>
									<p>
										Provide the Services
										<br>
										Internal research, including to maintain and develop the Services
										<br>
										Marketing
									</p>
								</td>
								<td>
									<p>
										Service providers
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										Inferences drawn from any personal information collected
									</p>
								</td>
								<td>
									<p>
										Provide the Services
										<br>
										Internal research, including to maintain and develop the Services
									</p>
								</td>
								<td>
									<p>
										Service providers
										<br>
										Subsidiaries &amp; Affiliates
										<br>
										Partners
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					In addition to those purposes described above, we may use or disclose
					any personal information that we collect as necessary to comply with applicable
					law or legal obligations as described in this Privacy Policy.
				</p>
				<p>
					Sale of Personal Information. You may exercise your right to opt out of
					the sale of your information as defined under the CCPA by clicking on the
					following link: DO NOT SELL MY DATA, and you can contact
					<a href="mailto:newsbig@outlook.com">
						newsbig@outlook.com
					</a>
					to exercise this right.
				</p>
				<p>
					California Residents’ Rights under the CCPA. Under the CCPA, California
					residents have certain rights regarding their personal information. Subject
					to certain exceptions, California residents have or may exercise the following
					rights:
				</p>
				<p>
					Right to Know about Personal Information Collected, Disclosed or Sold.
					A California resident has the right to be informed about the information
					practices of a business, and the specific pieces of personal information
					that a business holds about that resident.
				</p>
				<p>
					Right to Request Deletion of Personal Information. A California resident
					may request that a business delete their personal information.
				</p>
				<p>
					Right to Opt-Out of the Sale of Personal Information. A California resident
					has the right to opt-out of the sale of their personal information by a
					business.
				</p>
				<p>
					Right to Non-Discrimination for Exercise of Consumer Privacy Rights. A
					California resident has the right not to receive discriminatory treatment
					by a business for the exercise of privacy rights provided by the CCPA.
				</p>
				<p>
					To submit requests to us to exercise these rights, California residents
					may use the following methods:
				</p>
				<ul>
					<li>
						Contact us through our Website at _{{host}}_.
					</li>
					<li>
						Contact us by email at
						<a href="mailto:newsbig@outlook.com">
							newsbig@outlook.com
						</a>
						.
					</li>
				</ul>
				<p>
					We will not discriminate against you for exercising your rights under
					the CCPA. An agent may submit a request on your behalf, but you must verify
					that your agent is authorized to do so. Please understand that, before
					responding to your request, we may ask you for additional information in
					order to verify your identity, or take other actions that we believe are
					appropriate in order to verify your identity.
				</p>
				<p>
					Separate from the CCPA, California’s Shine the Light law gives California
					residents the right to request information regarding the disclosure of
					your personal information by us to a third party for the third party's
					direct marketing purposes. To make such a request, you may contact us through
					our Website at
					<a :href="`https://${host}`" previewlistener="true">
						{{host}}
					</a>
					or by email at
					<a href="mailto:newsbig@outlook.com">
						newsbig@outlook.com
					</a>
					.
				</p>
				<h3>
					7. Use of Cookies &amp; Other Tracking Technologies
				</h3>
				<p>
					We may use cookies or other similar tracking technology (collectively
					“Cookies”) to collect certain information about you when you are visiting
					the Website. Cookies are small text files that are stored automatically
					on your browser by a website during your visit. Cookies cannot be used
					to run programs or deliver viruses to your computer. Cookies may contain
					unique identifiers, and reside, among other places, on your computer or
					mobile device, in emails we send to you, and on our web pages. Cookies
					may transmit information about you and your use of the Services, such as
					your browser type, search preferences, IP address, data relating to advertisements
					that have been displayed to you or that you have interacted with, and the
					date and time of your use. Cookies may be persistent or stored only during
					an individual session.
				</p>
				<p>
					We use Cookies in the Services including the following purposes:
				</p>
				<div class="info-table">
					<table border="1" cellspacing="0" cellpadding="00" frame="void" rules="all">
						
						<tbody>
							<tr>
								<td>
									<p>Purpose</p>
								</td>
								<td>
									<p>Explanation</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Processes</p>
								</td>
								<td>
									<p>To make the Services work in the way you expect.</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Authentication, Security, and Compliance</p>
								</td>
								<td>
									<p>To prevent fraud, protect your data from unauthorized parties, and comply
									with legal requirements.</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Preferences</p>
								</td>
								<td>
									<p>To remember information about how you prefer the Services to behave and
									look.</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Notifications</p>
								</td>
								<td>
									<p>To allow or prevent notices of information or options that we think could
									improve your use of the Services.</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Advertising</p>
								</td>
								<td>
									<p>To make advertising more relevant to users and more valuable to advertisers.</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Analytics</p>
								</td>
								<td>
									<p>To help us understand how visitors use the Services.</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					If you do not accept these Cookies, you can change your browser’s settings
					to delete cookies that have already been set and to not accept new Cookies.
					Please note, however, if you delete Cookies or do not accept them, you
					might not be able to use all of the features we offer, you may not be able
					to store your preferences, and some of our pages might not display properly.
					Please note that changing any of these settings does not prevent the display
					of certain advertisements to you. In certain countries, by using the Website,
					you consent to the use of the Cookies. Where required by relevant applicable
					laws, we will seek your consent.
				</p>
				<h3>
					8. Our Policy Towards Children
				</h3>
				<p>
					We comply with the age-gating requirements under applicable privacy laws
					such as Personal Information Protection Law of People’s Republic of China,
					Children's Online Privacy Protection Act (COPPA), California Consumer Privacy
					Act (CCPA) in the U.S. and GDPR in EEA and UK, and do not knowingly collect
					personal information or personal data about users of sites or apps directed
					to children. If we need to process personal information of children, we
					shall seek permission from the parents or other guardians of children.
				</p>
				<p>
					If you become aware that a child has provided us with personal information
					without parental consent, please contact us at
					<a href="mailto:newsbig@outlook.com">
						newsbig@outlook.com
					</a>
					. If we become aware that a child has provided us with personal information
					without parental consent, we take immediate steps to remove such information
					and terminate such account.
				</p>
				<h3>
					9. How You Can Manage Your Marketing Preferences and Right to Opt Out
					of Devices
				</h3>
				<p>
					We may use personal information to let you know about our products and
					services that we believe will be of interest to you. We may contact you
					through your devices or through other communication channels that we think
					you may find helpful.
				</p>
				<p>
					To protect your privacy rights and to ensure you have control over how
					we manage marketing with you, we will take steps to limit direct marketing
					to a reasonable and proportionate level, and only send you communications
					which we believe may be of interest or relevant to you. You can ask us
					to stop direct marketing at any time. You can ask us to stop sending email
					marketing, by following the ‘unsubscribe’ link you find on all the email
					marketing messages we send you. Alternatively, you can contact us at
					<a href="mailto:newsbig@outlook.com">
						newsbig@outlook.com
					</a>
					. You can also change the way your browser manages cookies, which may
					be used to deliver online advertising, by following the settings on your
					browser as explained in section 7 above.
				</p>
				<p>
					Furthermore, we respect your privacy as a user, and you can opt-out from
					receiving interest-based advertising from us on supported devices. You
					can opt out of having your Mobile IDs used for certain types of interest-based
					mobile advertising (also called “cross-app advertising”), including those
					performed by us, by accessing the settings on your Apple or Android mobile
					device, as follows:
				</p>
				<p>
					Apple Devices: If you have an Apple device, you can opt out of most cross-app
					advertising as follows:
					<br>
					iOS 14 and Higher: Go to Settings → Privacy → Apple Advertising, and toggle
					“Personalized Ads” to ‘OFF’
					<br>
					More information about the iOS setting can be found here:
					<a href="https://support.apple.com/en-us/HT202074" previewlistener="true">
						https://support.apple.com/en-us/HT202074
					</a>
					<br>
					Android Devices: If you have an Android device, you can opt out of most
					cross-app advertising by going to Google Settings → Ads, and selecting
					the option to opt out of interest-based ads.
					<br>
					When you have opted out using this setting on a device, we will not use
					in-app information collected from that device to infer your interests or
					serve ads to that device that are targeted based on your inferred interests.
				</p>
				<p>
					Harmony Devices: If you have a Harmony device, you can opt Settings →
					Privacy → Advertising and Privacy → toggle “Limit Ad Tracking” to ‘ON.
				</p>
				<p>
					These platforms control how these settings work, so the above instructions
					may change, and the precise language may be different on certain (particularly,
					on older) devices. Likewise, if your device uses other platforms not described
					above, please check the settings for those devices. Please note that while
					opting out limits our collection of information to serve you ads based
					on your inferred interests, it does not prevent you from receiving ads
					from us, Facebook, Google, or third parties.” We recommend you routinely
					review the privacy policies and preference settings that are available
					to you on any social media platforms as well as this Privacy Policy.
				</p>
				<h3>
					10. Send “Do Not Track” Signals
				</h3>
				<p>
					Some browsers have incorporated “Do Not Track” (DNT) features that can
					send a signal to the websites a user visits indicating user does not wish
					to be tracked. Because there is not yet a common understanding of how to
					interpret the DNT signal, our Services do not currently respond to browser
					DNT signals.
				</p>
				<h3>
					11. Third Party Websites
				</h3>
				<p>
					In the event that we provide links to other websites that we do not control, whether or not those other websites refer to our branding, this Privacy Policy does not apply to those other websites.Visitors to those websites are advised to carefully read the policies on those individual websites. However, our agreements with these service providers limit the types of information these companies collect, how they may collect it, and how they may use it.
				</p>
				<h3>
					12. Contact us
				</h3>
				<p>
					If you have any question or comment about this Policy, Contact us via
					<a href="mailto:newsbig@outlook.com">
						newsbig@outlook.com
					</a>
				</p>
			</div>
</section>
</CommonPage>
</template>

<script>
import '@/css/news/privacyterms.scss';
import CommonPage from '@/pages/CommonPage.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    CommonPage
  },
  computed: {
    ...mapGetters([
      'host'
    ])
  },
};
</script>
<style scoped>
	.info-table{
		table tr td{
			padding: 15px;
		}
	}
</style>